import { ref, computed, watch, watchEffect, onMounted } from "vue";
import moment from 'moment';
import { message } from "ant-design-vue";
import dymaicRules from "@/components/Address/hook/index"
import { useI18n } from "vue-i18n";
import store from "@/store";
import { renderUnit } from "@/utils/common"
import axios from "axios";
import AnalyzePostalcode from "@/components/Address/hook/analyze-postalcode"
import setting from "@/config/setting"
const PATTERN_SP = new RegExp("[`~! @#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？%+]", "g")

export default () => {
    /* 初始化数据 */
    const declared = ref([])
        , $t = useI18n().t;

    axios.get('/admin/currency/list', {
        params: {
            page: 1,
            limit: 9999,
            sort: "sort",
            order: "asc"
        }
    }).then(res => {
        if (res.data.code == 0) {
            declared.value = res.data.data;
        }
    }).catch(e =>{
        message.error(e.message)
    });

    /* 单位引用 */
    const default_unit = function () {
        const obj = JSON.parse(localStorage.getItem('all_unit'));

        // 兼容
        if (obj) {
            delete obj.manufacture_country_code;
            obj.item_weight_unit = obj.item_weight_unit || 'lbs'
        }

        return obj || {
            dims_unit: 'in',
            weight_unit: 'lbs',
            item_weight_unit: 'lbs',
            declared_value_currency: 'CNY',
            // manufacture_country_code: 'CN',
            declared_unit: "CNY"
        }
    }
    /* 表单绑定ref */
    const obj_all_unit = ref(default_unit())
        , address_form = ref()
        , packages_form = ref();

    const stopUnitWatch = watchEffect(
        obj_all_unit,
        (val) => {
            localStorage.setItem('all_unit', JSON.stringify(val));
        },
        { deep: true }
    )

    const form = ref(
        // 表单数据
        {
            // 订单ID
            id: 0,
            customer_reference: '',
            ship_date: moment(new Date(), 'YYYY-MM-DD'),

            global_reference1: '',
            global_reference2: '',
            // 地址信息
            address: {
                // 发货地址ID
                shipper_address_id: localStorage.getItem('cache_shipper_address_id') ? JSON.parse(localStorage.getItem('cache_shipper_address_id')) : 0,
                country_code: 'US'
            },
            // 箱列表
            packages: [],
            // 物品列表
            items: [],
            // 是否保存地址到地址簿
            save_address: false,
            duty_type: localStorage.getItem("duty_type") || "",
            sign_type: "NO_SIGNATURE",
            tax_payer: "Receiver",
            reason_for_export: localStorage.getItem("cache_reason_for_export") || "SALE",
            // 包裹类型
            package_type: "P"
        })
        , address = computed(() => form.value.address)
        , items = computed(() => form.value.items)
        , addItems = () => {
            if (items.value.length > 0) {
                items.value.push({
                    pieces: '1',
                    weight_unit: obj_all_unit.value.item_weight_unit,
                    declared_value_currency: obj_all_unit.value.declared_value_currency,
                    manufacture_country_code: obj_all_unit.value.manufacture_country_code || items.value[items.value.length - 1].manufacture_country_code || '',
                    unit: obj_all_unit.value
                })
            } else {
                items.value.push({
                    pieces: '1',
                    weight_unit: obj_all_unit.value.item_weight_unit,
                    declared_value_currency: obj_all_unit.value.declared_value_currency,
                    manufacture_country_code: obj_all_unit.value.manufacture_country_code,
                    unit: obj_all_unit.value
                })
            }
        }
        , clearItems = () => {
            form.value.items = [];
        }
    /**
     * 移除箱
     */
    const removeItems = (row) => {
        if (items.value.length > 1) {
            items.value.splice(items.value.indexOf(row), 1);

            message.success('user_order.tips.delete_success');
        }
    }
    // 添加规则
    let global_reference1_rules = [];
    let order_rules = {};

    // 定制规则 - 策略模式
    let rule_strategy = {
        'hyd.lbl.morelink56.com':{
            global_reference1:[
                {
                    required: true,
                    message: $t('share.require'),
                    trigger: 'blur'
                }
            ],
            customer_reference:[
                {
                    required: true,
                    message: $t('share.require'),
                    trigger: 'blur'
                }
            ]
        },
        'fhjy.lbl.morelink56.com':{
            global_reference2:[
                {
                    required: true,
                    message: $t('share.require'),
                    trigger: 'blur'
                }
            ]
        },
        'first-star.lbl.morelink56.com':{
            global_reference2:[
                {
                    required: true,
                    message: $t('share.require'),
                    trigger: 'blur'
                }
            ]
        }
    }
    Object.assign(order_rules,rule_strategy[setting.env]);

    // 表单验证规则
    const { rules, countries, countryFilter, countries_rules } = dymaicRules(address_form, address);
    rules.value = Object.assign(rules.value, {
        ship_date: [{
            required: true,
            message: $t('share.require'),
            type: 'date',
            trigger: 'blur'
        }],
        shipper_address_id: [{
            required: true,
            message: $t('share.require'),
            type: 'string',
            trigger: 'blur'
        }],
        phone_number: [{
            required: true,
            message: $t('share.require'),
            trigger: 'blur'
        }, {
            pattern: /^([0-9]|-){0,}$/,
            message: $t('格式只支持数字、-'),
            trigger: 'blur'
        }],
        number: [{
            required: true,
            pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/,
            message: $t('share.require'),
            trigger: 'blur'
        }],
        freight: [
            {
                required: false,
                pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/,
                message: $t('请输入数字'),
                trigger: 'blur'
            }
        ],
        company_name: [
            {
                required: false,
                max: 35,
                message: $t('最大限制35个字符'),
                trigger: 'blur'
            }
        ],
        global_reference1: global_reference1_rules,
        ...order_rules
    })

    /* 重构方案 */
    // 发货地址列表
    const shippers = ref([])
        // 判断是否显示报关
        , allow_customs_data = ref(true)
        /* 系统是否需要报关 */
        , system_config = computed(() => {
            if (!form.value.items || form.value.items.length <= 0) {
                addItems();
            }
            return store.state.system.config
        })

    const handleChangeCountryCode = (val) => {
        countries_rules(val)

        if (system_config.value.allow_customs_data == '1') {
            const obj = shippers.value.find(item => item.id == form.value.address.shipper_address_id);
            if (obj && obj.country_code != val) {
                // allow_customs_data.value = true;
                if (form.value.items.length <= 0) {
                    addItems();
                }
            } else {
                // allow_customs_data.value = true;
                form.value.items = [];
            }
        }

        address_form.value.clearValidate();
    };

    watch(
        () => address.value.shipper_address_id,
        (val) => {
            if (system_config.value.allow_customs_data == '1') {
                const obj = shippers.value.find(item => item.id == val);
                if (obj && obj.country_code != address.value.country_code) {
                    // allow_customs_data.value = true;
                    if (form.value.items.length <= 0) {
                        addItems();
                    }
                } else {
                    // allow_customs_data.value = true;
                    form.value.items = [];
                }
            }
        },
        {
            immediate: true
        }
    )

    /* 验证功能 */
    const unpass_length = ref(0);
    async function form_validate() {
        let length = 0;

        await address_form.value.validate().catch(e => {
            length += e.errorFields.length
        });
        await packages_form.value.validate().catch(e => {
            length += e.errorFields.length
        });

        unpass_length.value = length;

        if (length > 0) {
            return Promise.reject(length)
        } else {
            return Promise.resolve(length)
        }
    }

    const LayoutBodyRef = ref()
    onMounted(() => {
        LayoutBodyRef.value = document.querySelector(".ele-admin-content")
    });

    const affixed = ref(false);

    const analyzePostalcode = AnalyzePostalcode({ address, address_form, rules });

    const handlePhone = (e) => {
        address.value.phone_number = e.target.value.replace(PATTERN_SP, "");
    }

    const handleChangeDutyType = (val) => {
        localStorage.setItem("duty_type", val);
    }

    return {
        address_form,
        packages_form,
        shippers,
        form,
        address,
        items,
        allow_customs_data,
        addItems,
        clearItems,
        removeItems,
        obj_all_unit,
        declared,
        system_config,
        rules,
        countries,
        countryFilter,
        handleChangeCountryCode,
        stopUnitWatch,
        unpass_length,
        form_validate,
        renderUnit,

        LayoutBodyRef,
        affixed,

        ...analyzePostalcode,

        handlePhone,
        handleChangeDutyType
    }
}