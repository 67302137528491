import { ref, watch } from "vue"
import { useI18n } from "vue-i18n";
import axios from "axios"
// const CODE_ARR = ['HK', 'ES', 'MO', 'GI', 'TD', 'ZM', 'IQ', 'JM', 'UG', 'TV', 'TT', 'TC', 'TO', 'TZ', 'SB', 'SR', 'SZ', 'LK', 'VC', 'LC', 'KN', 'SA', 'SC', 'SN', 'SL', 'WS', 'NF', 'NG', 'NE', 'NP', 'NI', 'NR', 'NA', 'MZ', 'PE', 'BD', 'MS', 'VI', 'MR', 'MU', 'ML', 'MW', 'MV', 'RW', 'LY', 'LR', 'LB', 'LA', 'LS', 'CW', 'CK', 'KE', 'KW', 'CI', 'KY', 'QA', 'CM', 'ZW', 'GA', 'GH', 'GN', 'DJ', 'KI', 'HN', 'HT', 'GY', 'GD', 'CR', 'CD', 'CG', 'GM', 'CV', 'FJ', 'GF', 'ER', 'DO', 'DM', 'TG', 'GQ', 'BI', 'BF', 'BT', 'BQ', 'BW', 'BZ', 'BO', 'BJ', 'PA', 'BH', 'PY', 'PK', 'BS', 'PG', 'BB', 'AG', 'AI', 'AO', 'IE', 'AE', 'AW', 'OM', 'EG', 'ET']
// const CODE_ARR2 = ['US', 'CA']
export default (formRef, form) => {
    const $t = useI18n().t;
    // 表单验证规则
    const rules = ref({
        name: [{
            required: true,
            message: $t('share.require'),
            type: 'string',
            trigger: 'blur'
        }, {
            max: 35,
            message: $t('最大限制35个字符'),
            trigger: 'blur'
        }],
        address1: [{
            required: true,
            message: $t('share.require'),
            type: 'string',
            trigger: 'blur'
        }, {
            max: 35,
            message: $t('最大限制35个字符'),
            trigger: 'blur'
        }],
        address2: [{
            max: 35,
            message: $t('最大限制35个字符'),
            trigger: 'blur'
        }],
        address3: [{
            max: 35,
            message: $t('最大限制35个字符'),
            trigger: 'blur'
        }],
        city: [{
            required: true,
            message: $t('share.require'),
            type: 'string',
            trigger: 'blur'
        }],
        state_code: [{
            required: false,
            message: $t('share.require'),
            type: 'string',
            trigger: 'blur',
            disabled: false
        }],
        postal_code: [{
            required: false,
            message: $t('share.require'),
            type: 'string',
            trigger: 'blur',
            disabled: false
        }],
        country_code: [{
            required: true,
            message: $t('share.require'),
            type: 'string',
            trigger: 'blur'
        }],
        phone_number: [{
            required: true,
            message: $t('share.require'),
            type: 'string',
            trigger: 'blur'
        }]
    })
        , countries = ref([])

        /**
         * 选择国家更改规则
         */
        , countries_rules = () => {

            // if (CODE_ARR.includes(val)) {

            //     rules.value.state_code[0].disabled = true;
            //     rules.value.postal_code[0].disabled = true;
            //     rules.value.state_code[0].required = false;
            //     rules.value.postal_code[0].required = false;

            //     form.value.state_code = ""
            //     form.value.postal_code = ""

            // } else if (CODE_ARR2.includes(val)) {

            //     rules.value.state_code[0].disabled = false;
            //     rules.value.postal_code[0].disabled = false;
            //     rules.value.state_code[0].required = true;
            //     rules.value.postal_code[0].required = true;

            // } else {

            //     rules.value.postal_code[0].required = true;
            //     rules.value.postal_code[0].disabled = false;
            //     rules.value.state_code[0].disabled = true;
            //     rules.value.state_code[0].required = false;

            //     form.value.state_code = ""

            // }

        }

        , handleChangeCountryCode = (val, option) => {
            option.name && (form.value.country_name = option.name);
            countries_rules(val);

            !form.value.state_code && (form.value.state_code = "");
            !form.value.postal_code && (form.value.postal_code = "");
            formRef.value.clearValidate();
        };

    /**
     * 国家筛选
     */
    const countryFilter = (input, option) => {
        return option.value.toLowerCase().startsWith(input.toLowerCase()) ||
            option.label.toLowerCase().startsWith(input.toLowerCase());
    }

    axios.get('/admin/country/country_list').then(res => {
        countries.value = res.data.data;
    })


    watch(
        () => form.value.country_code,
        (val) => {
            countries_rules(val);

            formRef.value && formRef.value.clearValidate();
        },
        {
            immediate: true
        }
    )

    return {
        rules,
        countries,
        countryFilter,
        handleChangeCountryCode,
        countries_rules
    }
}
