import { util } from "ele-admin-pro"

export function renderUnit(row, key) {
    return (row[key] && row[key].toLocaleLowerCase()) || (key == 'dim_unit' ? 'in' : 'lbs')
}
/**
 * 验证美国电话
 */
export function phoneValidator(rule, value) {
    var patterns = new RegExp(/\d?(\s?|-?|\+?|\.?)((\(\d{1,4}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)((\(\d{1,3}\))|(\d{1,3})|\s?)(\s?|-?|\.?)\d{3}(-|\.|\s)\d{4}/);
    var patterns1 = new RegExp(/([+][3][0-9]{0,11})/);
    var patterns2 = new RegExp(/([+][3][0-9]{0,11})([ ][ext])(.)|([ ])[0-9]/);

    value = value && value.trim();

    return new Promise((resolve, reject) => {
        if (!value) {
            return reject();
        } else {
            if (((value.length === 10 || value.length === 11) && Number(value) >= 0) || patterns.test(value) || (value.length == 12 && patterns1.test(value)) || value.length == 21 && patterns2.test(value)) {
                resolve();
            } else {
                reject();
            }
        }
    });
}

/**
 * 转流
 */
export function base64ToBlob(text, content_type) {
    var byteString = atob(text);
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var intArray = new Uint8Array(arrayBuffer);

    for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([intArray], {
        type: content_type
    });
}

/**
 * 下载文件
 */
export function downloadFile(name) {
    const xhr = new XMLHttpRequest();
    const okStatus = document.location.protocol === 'file:' ? 0 : 200;

    xhr.open('GET', name, false);
    xhr.overrideMimeType('text/html;charset=utf-8');
    xhr.send(null);

    return xhr.status === okStatus ? xhr.responseText : null;
}

/**
 * 可控开关监听者
 * @param watch Vue.$watch
 * @key key String 只支持字符串
 * @cb 回调函数
 */
export class OpernationWatch {
    constructor(watch, key, cb) {
        this.$watch = watch;
        this.unWatch = null;
        this.cb = cb;
        this.key = key;
    }

    startWatch() {
        const un = this.$watch(this.key, this.cb);
        this.unWatch = un;
    }

    stopWatch() {
        this.unWatch();
    }
}

export function copyText(text, callback) { // text: 要复制的内容， callback: 回调
    var tag = document.createElement('input');
    tag.setAttribute('id', 'cp_hgz_input');
    tag.value = text;
    document.getElementsByTagName('body')[0].appendChild(tag);
    document.getElementById('cp_hgz_input').select();
    document.execCommand('copy');
    document.getElementById('cp_hgz_input').remove();
    if (callback) { callback(text) }
}

/**
 * 首字母大写
 * @params text string
 */
export function toLocaleLowerCaseIndex(text) {
    return text.charAt(0, 1).toLocaleUpperCase() + text.slice(1)
}

/**
 * 将菜单转树结构
 * @params data Array
 */
export function menuToTree(data, cb) {
    return util.toTreeData(data.map(d => {
        d.key = d.id;
        d.value = d.id;
        cb && cb(d);

        return d;
    }), 'id', 'parent_id');
}