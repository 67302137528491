import axios from "axios"

function cancelApi(api) {
    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
    if (this.apiPost === api) {
        return this.apiPost;
    }
    this.apiPost = api;
    return this.apiPost
}
cancelApi.prototype.new_token = function () {
    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
    this.apiPost = null;
}
cancelApi.prototype.cancel = function () {
    if (!this.apiPost) {
        return
    }
    this.source.cancel('cancel');
}
cancelApi.prototype.get_token = function () {
    return this.source.token
}

export default cancelApi;